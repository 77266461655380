import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bullets", "position" , "content"];
  openClass = 'lg-hotspot--selected';

  show(event) {
    event.preventDefault();
    const parent = event.currentTarget.parentElement;
    const isOpen = parent.classList.contains(this.openClass);

    for (let element of this.bulletsTargets) {
      element.classList.remove(this.openClass);
    }

    if (!isOpen) {
      event.currentTarget.parentElement.classList.add(this.openClass);
      let clone = event.currentTarget.parentElement.querySelector('.lg-hotspot__label').cloneNode(true);
      this.contentTarget.innerHTML = '';
      this.contentTarget.appendChild(clone);
    }
  }

  hide(event) {
    event.preventDefault();

    for (let element of this.bulletsTargets) {
      this.contentTarget.innerHTML = '';
      element.classList.remove(this.openClass);
    }
  }
}

