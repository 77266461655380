import { Controller } from "stimulus"
import {Feature, Overlay, View} from "ol"
import {Map} from "ol";
import {Fill, Stroke, Circle as CircleStyle, Style, Text} from 'ol/style';
import {fromLonLat} from 'ol/proj';
import {Point} from "ol/geom";
import {Attribution, Zoom} from "ol/control";
import {Cluster, OSM, Vector as VectorSource} from "ol/source";
import {Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import {boundingExtent} from "ol/extent";
import {Modal} from "bootstrap";
import {DragPan, MouseWheelZoom, defaults} from 'ol/interaction';
import {platformModifierKeyOnly} from "ol/events/condition";

export default class extends Controller {

    static targets = ['modal', 'modalHeader', 'modalDescription', 'modalImage', 'modalDetailPage'];

    connect() {
        this.draw();
    }

    #clusters;
    #source;

    draw() {
        this.fetchMarkers();
        this.generateClusters();

        const map = new Map({
            interactions: defaults({dragPan: false, mouseWheelZoom: false}).extend([
                new DragPan({
                    condition: function (event) {
                        return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
                    },
                }),
                new MouseWheelZoom({
                    condition: platformModifierKeyOnly,
                }),
            ]),
            controls: [
              new Zoom({
                  className: 'zoom'
              }),
                new Attribution({
                    className: 'attribution'
                })
            ],
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            target: 'osm',
            view: new View(
                {
                    zoom: 6,
                    minZoom: 1,
                    maxZoom: 20
                }
            ),
        });

        map.addLayer(this.#clusters);

        const latCenter = document.getElementById('map-lat');
        const lngCenter = document.getElementById('map-lng');
        if (latCenter && lngCenter) {
          map.getView().animate({zoom: 10}, {center: fromLonLat([parseFloat(lngCenter.textContent), parseFloat(latCenter.textContent)])});
        } else {
          map.getView().fit(this.#source.getExtent(), {duration: 1000, padding: [100, 100, 100, 100]});
        }
        // open modal on click (or zoom in)
        map.on('click', (e) => {
            this.#clusters.getFeatures(e.pixel).then((clickedFeatures) => {
                if (clickedFeatures.length) {
                    const features = clickedFeatures[0].get('features');

                    if (features.length > 1) {
                        const extent = boundingExtent(
                            features.map((r) => r.getGeometry().getCoordinates())
                        );

                        map.getView().fit(extent, {duration: 1000, padding: [100, 100, 100, 100]});
                    } else {
                        // single feature clicked
                        const feature = features[0];

                        if (feature.get('hasDetails')) {
                           // show modal

                            const modal = new Modal(this.modalTarget);

                            this.modalHeaderTarget.innerHTML = feature.get('name');

                            if (feature.get('description')) {
                                this.modalDescriptionTarget.innerHTML = feature.get('description');
                                this.modalDescriptionTarget.style.visibility = 'visible';
                            } else {
                                // cleanup if not present
                                this.modalDescriptionTarget.innerHTML = '';
                                this.modalDescriptionTarget.style.visibility = 'hidden';
                            }

                            if (feature.get('detailPage')) {
                                this.modalDetailPageTarget.href = feature.get('detailPage');
                                this.modalDetailPageTarget.style.visibility = 'visible';
                            } else {
                                // cleanup if not present
                                this.modalDetailPageTarget.style.visibility = 'hidden';
                            }

                            if (feature.get('image')) {
                                this.modalImageTarget.src = feature.get('image');
                                this.modalImageTarget.style.visibility = 'visible';
                            } else {
                                // cleanup if not present
                                this.modalImageTarget.src = '/';
                                this.modalImageTarget.style.visibility = 'hidden';
                            }

                            modal.show();
                        }
                    }
                }
            })
        })

        // change cursor on hover
        map.on('pointermove', function (e) {
            let hit = map.forEachFeatureAtPixel(e.pixel, function(feature, layer) {
                if (feature.get('features').length > 1) {
                    return 2;
                }

                if (feature.get('features').length === 1 && feature.get('features')[0].get('hasDetails')) {
                    return 1;
                }

                return 3;
            });

            if (hit === 1) {
                map.getTargetElement().style.cursor = 'help';
            } else if (hit === 2) {
                map.getTargetElement().style.cursor = 'zoom-in';
            } else if (hit === 3) {
                map.getTargetElement().style.cursor = 'not-allowed';
            } else {
                map.getTargetElement().style.cursor = 'move';
            }
        });
    }

    fetchMarkers() {
        let references = document.getElementsByClassName('map-pin');

        const features = new Array(references.length);

        for (let i = 0; i < references.length; i++) {
            const pin = references[i].dataset;

            const pinLng = parseFloat(pin.lng);
            const pinLat = parseFloat(pin.lat);
            const pinUid = parseInt(pin.uid);

            let feature = new Feature(new Point(fromLonLat([pinLng, pinLat])))
            feature.setId(pinUid);
            feature.set("name", pin.name);

            let hasDetails = false;
            if (pin.description) {
                feature.set("description", pin.description);
                hasDetails = true;
            }

            if (pin.image) {
                feature.set("image", pin.image);
                hasDetails = true;
            }

            if (pin.detailPage) {
                feature.set("detailPage", pin.detailPage);
                feature.set("hasDetailPage", true);
                hasDetails = true;
            } else {
                feature.set("hasDetailPage", false);
            }

            feature.set("hasDetails", hasDetails);

            features[i] = feature;
        }

        this.#source = new VectorSource({
            features: features,
        });
    }

    generateClusters() {
        let clusterSource = new Cluster({
            distance: 50,
            minDistance: 10,
            source: this.#source
        });

        // display the pins in clusters if needed (styling)
        this.#clusters = new VectorLayer({
            source: clusterSource,
            style: function (feature) {
                const size = feature.get('features').length;
                let text = '';

                let fill = new Fill({
                        color: '#39600D',
                    }
                )

                // true if pins are clustered
                if (size > 1) {
                    fill.setColor('#89CFF0');
                    text = size.toString();
                } else if (size === 1) {

                    // size is 1 => only 1 feature exists
                    const currentFeature = feature.get('features')[0];

                    // determine if color has to change due to extra information
                    if (currentFeature.get("hasDetailPage")) {
                        fill.setColor('#E30613');
                        // TODO: icon?
                        // show information is available
                        text = 'i';
                    }

                    if (currentFeature.get("hasDetails")) {
                        text = 'i';
                    }
                }

                return new Style({
                    image: new CircleStyle({
                        radius: 12,
                        stroke: new Stroke({
                            color: '#fff',
                        }),
                        fill: fill,
                    }),
                    text: new Text({
                        text: text,
                        fill: new Fill({
                            color: '#fff',
                        }),
                    }),
                });
            }
        })
    }
}
