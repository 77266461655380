import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "name", "address" ]

  initialize() {
  }

  connect() {
    const formField = document.getElementById("powermail_field_job");
    if (formField) {
      formField.value = this.nameTarget.dataset.id + ": " + this.nameTarget.textContent;
    }

    const formAddressField = document.getElementById("powermail_field_einsatzort");
    if (formAddressField && this.addressTarget) {
      formAddressField.value = this.addressTarget.textContent.trim();
    }
  }
}
