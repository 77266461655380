import {Controller} from "stimulus"
import {LineChart, Interpolation} from 'chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import ChartistTitlePlugin from 'chartist-plugin-axistitle'

export default class extends Controller {
  static targets = ["chart", "container"]

  static values = {
    series: Array,
    labels: Array
  }

  #enableAutoScroll

  checkOverflow()
  {
    const curOverflow = this.containerTarget.style.overflow;

    if ( !curOverflow || curOverflow === "visible" ) {
      this.containerTarget.style.overflow = "hidden";
    }

    const isOverflowing = this.containerTarget.clientWidth < this.containerTarget.scrollWidth || this.containerTarget.clientHeight < this.containerTarget.scrollHeight;
    this.containerTarget.style.overflow = curOverflow;

    return isOverflowing;
  }

  onVisible(callback) {
    new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if(entry.intersectionRatio > 0) {
          callback(this.containerTarget);
          observer.disconnect();
        }
      });
    }).observe(this.containerTarget);
  }

  scrollDelay(ms) {
    return new Promise(res => setTimeout(res, ms));
  }

  async enableAutoscrolling() {
    if (this.checkOverflow() && this.#enableAutoScroll) {
      await this.scrollDelay(2500);

      for (let x = 0; x <= 1000; x += 50) {
        this.containerTarget.scrollTo({
          top: 0,
          left: x,
          behavior: 'smooth'
        });
        await this.scrollDelay(100);
      }
      this.#enableAutoScroll = false;
    }
  }

  connect() {
    this.#enableAutoScroll = true
    this.onVisible(() => this.enableAutoscrolling());
    let chart = new LineChart(
      this.chartTarget,
      {
        labels: this.labelsValue,
        series: this.seriesValue
      },
      {
        chartPadding: {
          right: 80,
          bottom: 20,
          left: 20
        },
        fullWidth: true,
        lineSmooth: Interpolation.simple({
          divisor: 2
        }),
        low: 0,
        height: '350px',
        plugins: [
          ChartistTooltip(),
          ChartistTitlePlugin(
            {
              axisX: {
                axisTitle: "Zeit",
                axisClass: "ct-axis-title",
                offset: {
                  x: 20,
                  y: 50
                },
                textAnchor: "middle"
              },
              axisY: {
                axisTitle: "ct/kWh",
                axisClass: "ct-axis-title",
                offset: {
                  x: 0,
                  y: 15
                },
                flipTitle: true,
              }
            }
          )
        ]
      }
    );

    // Let's put a sequence number aside so we can use it in the event callbacks
    let seq = 0;
    const delays = 20;
    const durations = 500;

    // Once the chart is fully created we reset the sequence
    chart.on('created', () => {
      seq = 0;
    });

    // On each drawn element by Chartist we use the Svg API to trigger SMIL animations
    chart.on('draw', data => {
      seq++;

      if (data.type === 'line') {
        // If the drawn element is a line we do a simple opacity fade in. This could also be achieved using CSS3 animations.
        data.element.animate({
          opacity: {
            // The delay when we like to start the animation
            begin: seq * delays + 1000,
            // Duration of the animation
            dur: durations,
            // The value where the animation should start
            from: 0,
            // The value where it should end
            to: 1
          }
        });
      } else if (data.type === 'label' && data.axis.counterUnits.pos === 'x') {
        data.element.animate({
          y: {
            begin: seq * delays,
            dur: durations,
            from: data.y + 100,
            to: data.y,
            // We can specify an easing function from Svg.Easing
            easing: 'easeOutQuart'
          }
        });
      } else if (data.type === 'label' && data.axis.counterUnits.pos === 'y') {
        data.element.animate({
          x: {
            begin: seq * delays,
            dur: durations,
            from: data.x - 100,
            to: data.x,
            easing: 'easeOutQuart'
          }
        });
      } else if (data.type === 'point') {
        data.element.animate({
          x1: {
            begin: seq * delays,
            dur: durations,
            from: data.x - 10,
            to: data.x,
            easing: 'easeOutQuart'
          },
          x2: {
            begin: seq * delays,
            dur: durations,
            from: data.x - 10,
            to: data.x,
            easing: 'easeOutQuart'
          },
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'easeOutQuart'
          }
        });
      } else if (data.type === 'grid') {
        // Using data.axis we get x or y which we can use to construct our animation definition objects
        const pos1Key = (data.axis.units.pos + '1');
        const pos1Value = data[pos1Key];
        const pos1Animation = {
          begin: seq * delays,
          dur: durations,
          from: pos1Value - 30,
          to: pos1Value,
          easing: 'easeOutQuart'
        };

        const pos2Key = (data.axis.units.pos + '2');
        const pos2Value = data[pos2Key];
        const pos2Animation = {
          begin: seq * delays,
          dur: durations,
          from: pos2Value - 100,
          to: pos2Value,
          easing: 'easeOutQuart'
        };

        const animations = {
          [data.axis.units.pos + '1']: pos1Animation,
          [data.axis.units.pos + '2']: pos2Animation,
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'easeOutQuart'
          }
        };

        data.element.animate(animations);
      }
    });
  }
}
