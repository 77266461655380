import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "nav" ]

  initialize() {
    this.boundHandleMyEvent = this.handleMyEvent.bind(this);
  }

  connect() {
    window.addEventListener("scroll", this.boundHandleMyEvent);
  }

  disconnect() {
    window.removeEventListener("scroll", this.boundHandleMyEvent);
  }

  handleMyEvent(event) {
    let scrollpos = window.scrollY;

    if (scrollpos >= this.navTarget.offsetHeight) {
      this.navTarget.classList.add('scrolling')
    }
    else {
      this.navTarget.classList.remove('scrolling')
    }
  }
}
