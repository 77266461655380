import { Controller } from "stimulus"
import timezz from 'timezz'

export default class extends Controller {
  static targets = [ "timer" ]
  static values = { timestamp: String }

  connect() {
    timezz(this.timerTarget, {
      date: Date.parse(this.timestampValue),
      stopOnZero: true
    });
  }
}

